<template>
  <div class="serveManage">
    <ds-header title="权益管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目：">
        <a-select
            style="width: 200px"
            v-model="searchForm.projectId"
            placeholder="请选择所属项目"
        >
          <a-select-option :value="item.id" v-for="item in projectList" :key="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="权益名称：">
        <a-input v-model.trim="searchForm.benefitTitle" placeholder="请输入权益名称" class="inputClass"/>
      </a-form-item>
      <a-form-item label="图片类型：">
        <a-select
            style="width: 200px"
            v-model="searchForm.imageType"
        >
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="1">一排一张</a-select-option>
          <a-select-option value="2">一排两张</a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search"/>查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <div class="upload-button-class">
        <div>
          <a-upload
              name="file"
              accept=".xlsx, .xls"
              :multiple="true"
              action="/api/dscloud-appservice-management/web/benefit/v2/import-excel"
              :headers="headers"
              @change="uploadFile"
              :showUploadList="false"
          >
            <a-button
                type="primary"
                class="uploadClass"
            >
              <a-icon type="upload"/>
              批量导入
            </a-button>
          </a-upload>
        </div>
        <div>
          <a-button type="primary" @click="add">
            <a-icon type="plus"/>
            新增权益
          </a-button>
        </div>
      </div>
      <a @click="downLoadExcel">点击下载模版</a>
    </div>
    <div style="padding-top: 15px">
      <a-table :columns="columns" :pagination="false" :data-source="data" bordered>
        <template slot="benefitImage" slot-scope="text, record">
          <div class="editable-row-operations">
            <img v-if="text" width="100px" height="100px" :src="text"/>
          </div>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <span>
              <a @click="() => viewDetail(record.id)">查看</a>
            </span>
            <span>
              <a @click="() => edit(record.id)">编辑</a>
            </span>
            <span>
              <a @click="() => deleteById(record)">删除</a>
            </span>
          </div>
        </template>
      </a-table>
      <div class="pagination-class">
        <a-pagination
            :show-total="total => `共 ${total} 条`"
            :page-size="pagination.pageSize"
            :default-current="pagination.current"
            :total="total"
            @change="pageChange"
            @showSizeChange="onShowSizeChange"
            show-quick-jumper
            showSizeChanger
        />
      </div>
    </div>
  </div>
</template>


<script>
import {mapState} from 'vuex';
import ConfirmBoxModule from '@/components/confirmBoxModule';
import DSTable from '@/components/DSComponents/DSTable';
import {IMG_API} from '@/config';
import {
  getEquityManagementList,
  projectName,
  toDeleteEquityById
} from '../../../api/equityManagement';

const data = [];
export default {
  name: 'equityManagement',
  components: {
    DSTable,
    ConfirmBoxModule
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
      projectList: (state) => state.common.projectList
    }),

  },
  data() {
    return {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('user')).accessToken
      },
      // 当前处于编辑状态的数据
      nowData: {},
      // 编辑按钮是否可用
      editDisabled: false,
      data: [],
      total: 0,
      IMG_API: IMG_API,
      // 上传图片loading
      loading: false,
      searchForm: {
        projectId: undefined,
        benefitTitle: '',
        imageType: ''
      },
      pagination: {
        current: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '适用项目',
          dataIndex: 'projectList',
          width: '15%'
        },
        {
          title: '权益名称',
          dataIndex: 'benefitTitle'
        },
        {
          title: '权益图片',
          dataIndex: 'benefitImage',
          scopedSlots: {customRender: 'benefitImage'}
        },
        {
          title: '权益类型',
          dataIndex: 'benefitType',
          customRender: (text)=> {
            if (text === 1) {
              return '固定权益'
            } else if (text === 2) {
              return '临时权益'
            } else {
              return '企业权益'
            }
          }
        },
        {
          title: '图片类型',
          dataIndex: 'imageType',
          customRender: (text)=> {
            if (text === 1) {
              return '一排一张'
            } else if (text === 2) {
              return '一排两张'
            } else {
              return '-'
            }
          }
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '更新时间',
          dataIndex: 'gmtModified'
        },
        {
          title: '操作',
          scopedSlots: {customRender: 'operation'}
        }
      ]
    }
        ;
  },
  created() {
    this.list()
  },
  methods: {
    // 列表
    list() {
      getEquityManagementList(this.searchForm, this.pagination.current, this.pagination.pageSize).then(res => {
        if (res.code === '200') {
          this.data = res.data.items;
          this.total = res.data.total;
          this.editDisabled = false;
        } else {
          this.$message.error('获取列表失败');
        }
      });
    },
    // 下载模版
    downLoadExcel() {
      let url =
          'https://oss-workplace-prod.innoecos.cn/picture_prod/056e6d987cc10000_%E6%9D%83%E7%9B%8A%E7%AE%A1%E7%90%86%E6%A8%A1%E7%89%88.xls';
      window.open(url);
    },
    //查询
    search() {
      this.list();
    },

    // 上传文件
    uploadFile(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`);
        this.search();
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },

    //重置
    resetSearchForm() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.searchForm.benefitTitle = '';
      this.searchForm.projectId = [];
      this.searchForm.imageType = '';
      this.list();
    },

    //分页操作
    pageChange(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.list();
    },
    onShowSizeChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.list();
    },
    // 新增
    add (){
      this.$router.push({
        path: '/equityManagement/equityManagement/add',
        query: {
          type: 'add'
        }
      })
    },
    // 查看
    viewDetail (id){
      this.$router.push({
        path: '/equityManagement/equityManagement/add',
        query: {
          type: 'view',
          id: id
        }
      })
    },
    // 编辑
    edit (id){
      this.$router.push({
        path: '/equityManagement/equityManagement/add',
        query: {
          type: 'edit',
          id: id
        }
      })
    },
    // 删除
    deleteById(data) {
      let that = this
      this.$confirm({
        title: '确定要删除该条权益吗?',
        onOk() {
          toDeleteEquityById(data.id).then(res => {
            if (res.code === "200") {
              that.$message.success('操作成功')
              that.list();
            } else {
              that.$message.error('操作失败')
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });

    }
  }
};
</script>

<style lang="scss" scoped>
.inputClass {
  width: 200px;
}

.upload-button-class {
  display: flex;
  margin-bottom: 10px;

  & > div {
    padding-right: 15px;
  }
}

.editable-row-operations a {
  margin-right: 8px;
}

.pagination-class {
  padding-top: 20px;
  display: flex;
  flex-direction: row-reverse;
}

</style>
